<template>
  <div :class="classContainer">
    <d-body-top-bar
      :title="$t('views.playground.config')"
      :is-icons-content="false"
    >
      <div slot="tree">
        <label v-if="mobileView" class="inactive-tree mr-1">
          {{ $t('views.playground.your-activities-area') }} /
        </label>
        <label @click="$router.push('/playground')" class="inactive-tree link-area pointer">
          {{ $t('views.playground.all-area') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ topBarTitle }} </label>
      </div>
      <div slot="icons">
        <steps
          :step="1"
          @on:step-0:selected="$router.push({name: 'playground_update', params:{ id: $route.params.id }})"
        />
      </div>
    </d-body-top-bar>
    <div class="playground-creation-container">
      <description
        :btn-loading="btnLoading"
        @on:step-1:validated="onNextClick"
      />
      <b-row class="mt-3">
        <b-col class="pr-0">
          <block-prices
            v-if="playground"
            :reloaded="reloaded"
            :playground="playground"
            :is-playground-prices="false"
            :nicheActivitiesSelection="[]"
          />
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-center col-0-5 pl-0"
          align="center"
        >
          <img
            :src="svgIcon"
            width="50"
            height="50"
            class="object-fit-img"
          />
        </b-col>
        <b-col class="pl-0">
          <selected-block-prices
            v-if="playground"
            :playground="playground"
            :is-playground-prices="true"
            @reload-blockPrices="reloaded = !reloaded"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {getClubPlayground} from "@api/doinsport/services/playground/playground.api";
import Playground from "@/classes/doinsport/Playground";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    componentKey: 0,
    playground: null,
    reloaded: false,
    svgIcon: require('@/assets/icons/playground/Arrows.svg'),
    selectedBlockPrices: [],
  }),
  components: {
    Steps: () => import('@views/playground/steps/Menus'),
    Description: () => import('./Description'),
    BlockPrices: () => import('./informations/BlockPrices'),
    SelectedBlockPrices: () => import('./informations/SelectedBlockPrices')
  },
  props: {
    btnLoading: {
      type: Boolean,
      default: false
    },
    classContainer: {
      type: String,
      default: ''
    }
  },
  computed: {
    topBarTitle() {
      if (this.$route.name === 'playground_new') {
        return this.$t('views.playground.config');
      } else {
        return this.$t('views.playground.config');
      }
    },
    mobileView() {
      return this.$store.getters['layout/getInnerWidth'] >= 991;
    }
  },
  created() {
    getClubPlayground(this.$route.params.id)
      .then((response) => {
        this.playground = new Playground(response.data, {deserialize: true});
      })
      .finally(() => {
        this.isBusy = false;
      })
    ;
  },
  methods: {
    onNextClick() {
      const count = this.$store.getters["playgrounds/getBlockPricesTotalItems"];

      if (count === 0) {
        this.$flash(
          this.$t('views.playground.steps.step-1.informations.general.notif-title-no-activity-selected'),
          this.$t('views.playground.steps.step-1.informations.general.notif-description-no-activity-selected'),
          10000)
        ;
      } else {
        this.$router.push({name: 'playground_config-step2', params: {id: this.$route.params.id}});
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.playground-creation-container {
  background-color: #F9FAFA;
}
.col-0-5 {
  flex: 0 0 5.533333%;
  max-width: 5.533333%;
}
</style>
